

/*#önemli**/
/**bootstrao çakışmasını kaldır*/
svg{
  vertical-align: unset !important;
}

.brand{
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
  text-align: center;
  justify-content: center;
}


@media screen and (min-width: 800px) {
  ::-webkit-scrollbar {
    width: 4px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #374A5D;
    border-radius: 5px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.ant-carousel .slick-slide img{
  object-fit: cover;
  width: 100%;
}

.infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
  height: 300px;
}
.home-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}

.home-slider-img{
  border-radius: 15px !important;
}


/*çek sürükle sırala */
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}


/*
*{
  overflow-x: unset;
}

*/
/*
body{
  background-color: #000;
  background-attachment: fixed;
  background-image: url(https://emedya-cdn.tjk.org/dist/images/bg-main_1.jpg);
  background-position: center;
  background-repeat: no-repeat;
}
*/
.p13{
  padding-left: 13px !important;
}


/*çek sürükle sırala */
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
  visibility: hidden;
}

.row-dragging .drag-visible {
  visibility: visible;
}



/*Custom style*/

.selected-ganyan-title{
    background-color: rgb(201, 0, 30);
    border-color: rgb(201, 0, 30);
    font-weight: bolder;
}

.selected-ganyan-type-title{
  background-color: #000000;
    color: #ff0022 !important;
    font-weight: 700;
    text-align: left;
}
.default-ganyan-type-title{
  background-color: #000000;
    color: #FFF !important;
    text-align: left;
}

.at-selected {
  background-color: #6c757d;
  border-color: #6c757d;
  color: #fff;
}
.clas2{
  text-align: center;
  width: 23px;
  height: 23px;
  font-size: 0.8em;
}

.clas1{
    width: 24px;
    height: 24px;
    border: #f2f2f2 solid 1px;
    border-radius: 5px;
    margin-bottom: 3px;
    cursor : pointer

}
.hb-c-default{
  color: #6c757d;
  border: #6c757d solid 1px;
}
.hb-c-default-selected{
  color: #fff;
  background-color:#6c757d;
}
.hb-c-kosmez{
 background-color:  #ff0022;
 color: #fff;
}

.hb-c-agf{
  color:  #28a745;
  border: #28a745 solid 2px;
  font-weight: 900;
 }
 .hb-c-agf-td{

  background-color:#28a745;
 }
 .hb-c-agf-td > span {
  color: #fff !important;
 }

 .hb-c-agf-selected{
  color: #fff;
  background-color:#28a745;
 }
 tbody{
   background: unset;
 }

 .loginlogo {
  /*font-family: Arsenal;*/
  color: #fff;
  font-size: 58px;
  text-shadow: 1px 1px 1px #000, 2px 2px 2px #000, 3px 3px 3px #000;
  text-align: left;
  letter-spacing: -5px;
  font-weight: 700;
  line-height: 80px;
  position: relative;
  opacity: 0.8;
  /*z-index: -1;*/
}
.bg-w-p-10-r10{
  background-color: rgba(256,256,256,0.7);
  border-radius: 5px;
  padding: 10px;
  height: 50px;
  margin-bottom: 10px;
}


/* dropdown menu */
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

input[type=radio] {
  display: none;
}

.a-container {
  margin: 10px auto;
}
.a-items>label{
  margin-bottom: 0;
}
.a-container label {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 14px;

  padding: 2px 10px;
  color: #fff;
  background-color: #000000;
  border-bottom: 1px solid #ddd;
  
  -webkit-transition: all .2s ease;
  -moz-transition: all .2s ease;
  -ms-transition: all .2s ease;
  -o-transition: all .2s ease;
  transition: all .2s ease;
}

.a-container label:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 8px solid #fff;
  border-right: 6px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 6px solid transparent;
  position: absolute;
  right: 10px;
  top: 10px;
}

.a-container input:checked + label,
.a-container label:hover {
  background-color: #670303;
  color: #fff;
}
.a-container input:checked + label {
  font-weight: bold;
}

.a-container input:checked + label:after {
  border-top: 8px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid #FFF;
  border-left: 6px solid transparent;
  top: 6px;
}

.a-content {
  padding: 10px 20px 20px;
  display: none;
  background-color: rgba(0, 0, 0, 0.7);
}

.a-container input:checked ~ .a-content {
  display: block;
}

/*** END dropdown menu***/

/* sMenuBtnDefault*/
.sMenuBtnDefault{
    width: 85%;
    cursor: pointer;
    color: wheat;
    padding-left: 4px;
    background-color: #ff0022;
    margin-bottom: 10px;
    border-radius: 0 10px 10px 0;
}
.sMenuBtnSelect{
  font-weight: 800;
  color: #000000;
  text-align: center;
  background-color: rgba(255, 251, 251, 0.7);
  border: 1px solid #ffffff;
  border-left-width: 0px;
  border-radius: 0 10px 10px 0;
}

.sMenuBtn{
  margin-bottom: 5px;
  cursor: pointer;
}
.sMenuBtn:hover{
  background: unset;
}

.sMenuBtnSelect:hover{
  color:white;
  border-left-width: 1px;
}

[data-page=bet-page] .bet-coupon .bet-coupon-scrolled-body [data-selector=normalBetCheckboxs], [data-page=bet-page] .bet-coupon-sm .bet-coupon-scrolled-body [data-selector=normalBetCheckboxs]{
  width: auto;
}
.pmisli{
  height: calc(1.5em + .5rem - 2px);
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem;
    float: right;
    border-width: 0;
}
.ptarih{
  width: 100%;
  font-size: 20px;
  padding: 5px 5px;
  border: 2px solid #c9001e;
  color: #c9001e;
}
.panelactivelink{
  border-bottom: 4px solid #fff;
  border-radius: 10px;
  font-weight : 600;
  padding: 0 10px;
}


.header-coupon{
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 10px;
  border-bottom: 1px solid #000;
  padding: 10px;
}

.border-bottom{
  border-bottom: 1px solid #000;
}

.pr-10{
  padding-right: 10px;
}
.pl-10{
  padding-left: 10px;
}
.w100{
  width: 100%;
}

.footer-container{
  display: none;
}
a.logo{
  display: none !important;
}

.hunderline:hover {
  text-decoration: underline !important;
}
.ww120{
  width: 90px;
}

.ww1200{
  width: 220px !important;
}



.content1 {
  /* this is needed or the background will be offset by a few pixels at the top */
  overflow: auto;
  position: relative;
}

.content1::before {
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  z-index: -1;

  display: block;
  background-image: url('https://pistatic.piyanos.com/uploads/chatx/useravatars/2ef59823dd234afefb6e298d987198a4.jpg');
  background-size:cover;
  width: 100%;
  height: 100%;

  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.useravatar img {
  width: 100px;
  height: 100px;
  max-width: 100px;
  max-height: 100px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.5);
}
.hovercard .useravatar {
  position: absolute;
   /* top: 15px;*/
    top: 50px;
    left: 0;
    right: 0;
}

.card-background img {
  -webkit-filter: blur(25px);
  -moz-filter: blur(25px);
  -o-filter: blur(25px);
  -ms-filter: blur(25px);
  filter: blur(25px);
  margin-left: -100px;
  margin-top: -200px;
  min-width: 130%;
}
.hovercard .card-background {
  height: 200px;
}
.hovercard {
  position: relative;
  padding-top: 0;
  overflow: hidden;
  text-align: center;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 1);
  margin-bottom: 50px;
}
.hovercard .card-info .card-title {
  padding:0 5px;
  font-size: 20px;
  line-height: 1;
  color: #262626;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.hovercard .card-info {
  overflow: hidden;
  font-size: 12px;
  line-height: 20px;
  color: #737373;
  text-overflow: ellipsis;
}
.hovercard .card-info {
  position: absolute;
  bottom: 14px;
  left: 0;
  right: 0;
}

.cursor-p{
  cursor: pointer;
}
.pointer{
  cursor: pointer;
}

.mb-10{
  margin-bottom: 10px;
}
