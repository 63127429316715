.messenger {
  display: grid;
  width: 100%;
  height: 100vh;
  background: #eeeef1;

  grid-template-columns: 350px auto;
  grid-template-rows: 60px auto 60px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
}

.messenger1{
  width: 100%;
  display: grid;
  background: #eeeef1;
  grid-template-columns: 350px auto;
  grid-template-rows: 60px auto 60px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
}

.h100{
  height: 100vh;
}
.pfixed{
  position: fixed;
}

.container {
  padding: 10px;

}

.scrollable {
  position: relative;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
/*
.scrollable1 {
  position: relative;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
*/

.sidebar {
  height: 100vh;
  background: white;
  grid-row-start: 1;
  grid-row-end: span 3;
}

.content {
  background: white;
  grid-row-start: 1;
  grid-row-end: span 3;
}

.footer {
  grid-column-start: 2;
  background: white;
}